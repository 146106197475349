header {
  position: fixed;
  padding-block: 20px ;
  z-index: 1000;
  bottom: 0px;
  left: 0;
  right: 0;
  background: white;
  @include centerChilds;
  flex-direction: column;

  @include notXlg{
    padding-bottom: 100px;
  }
  p{
    font-size: 2rem;
    margin-block: 5px;
    color: $primary-color;
    text-align: center;
    &:nth-of-type(2){
      font-weight: bold;
    }

    &:nth-of-type(3){
      font-size: 1rem;
    }

    @include md{
      font-size: 1.3rem;
    }
  }
  @include xlg {
    margin-block: 25px;
    padding: 20px min(50px, 5%);
    position: relative;
  }

  .ok-button{
    margin-top: 20px;
    background: $primary-color;
    padding: 10px 30px;
    border-radius: 10px;
    outline: none;
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    color: $white-color;
    cursor: pointer;
    transition: all 0.5s ease-in-out;
    &:hover{
      transform: scale(1.1);
    }
  }

}


.overlay{
  position: fixed;
  inset: 0;
  z-index: 999;
}