
.home-container{

  .revealer-container {
    position: relative;
    overflow: hidden;
    max-height:100dvh ;
    height: 100%;
    margin-inline: auto;
  
    > img {
      width: 100%;
      transition: all 0.5s ease-in-out;
  
      &:not(.hidden),&.bg-image {
        position: absolute;
        inset: 0;
        z-index: -1;
        height: 100%;
      }
      &.hidden {
        visibility: hidden;
      }
    }
  }
}

