// @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css");
// @import "primereact/resources/themes/lara-light-indigo/theme.css";
// @import "primeicons/primeicons.css";
// @import "primereact/resources/primereact.min.css";

@font-face {
    font-family: 'Poppins';
    src: url('../assets/fonts/Poppins/Poppins-Light.ttf') format('truetype');
  

}


@import "./_shared.scss";
@import "./revealer";
@import "./cloud";
@import "./header";
@import "./footer";
@import "./result";